<template>
    <div class="bs-4 main-header-container">
        <div class="main-header container fl-te-c">
            <input id="toggle" type="checkbox">
            <div class="logo">
                <!--                <router-link to="/components/">-->
                <router-link to="/">
                    <img src="@/assets/images/logo.png" class="logo-img" alt="logo">
                </router-link>
                <!--                <label for="hamb">-->
                <!--                    <img src="@/assets/images/hover.png" class="burger" alt="burger">-->
                <!--                </label>-->

                <label for="toggle" class="hamburger" @click="close">
                    <div class="top-bun"></div>
                    <div class="meat"></div>
                    <div class="bottom-bun"></div>
                </label>
            </div>
            <!--            <input id="hamb" type="checkbox" name="hamb" value="hamb">-->

            <div class="nav-list">
                <ul class="d-flex proxima-semi-bold">
                    <li @click="clicked">
                        <router-link class="c-menu" to="/">
                            <i class="fa fa-home d-none d-lg-block fs-6 mr-0" style="margin-bottom: 7px;"></i>
                            <!--                            <img src="@/assets/images/home.png" alt="home" class="d-none d-lg-block">-->
                            <span class="d-lg-none">Home</span>
                        </router-link>
                    </li>
                    <li @click="clicked">
                        <router-link class="c-menu" to="/about/">About us</router-link>
                    </li>
                    <li class="drop-menu">
                        <label class="mega-menu" ref="serviceLink" for="toggleDrop">Services</label>
                        <input id="toggleDrop" type="checkbox">
                        <div class="drop-menu-list" ref="services">
                            <div class="drop-menu-head">
                                <img src="../../assets/images/recent-blog/view-more.png" @click="clickedDrop" alt="#">
                            </div>
                            <div class="drop-menu-head-web">
                                <p class="b-gray br-1 font-weight-bold fs-4 mb-0 pr-3">
                                    Our Services
                                </p>
                                <p class="mb-0 ml-3 text-gray poppins-regular">Customized software solutions at your disposal</p>
                                <img src="../../assets/images/close-button.png" class="ml-auto drop-close d-none" alt="#"
                                     @click="clicked">
                            </div>
                            <ul>
                                <li class="drop-sub-menu">
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div @click="clicked">
                                                <router-link to="/software-development-thrissur-kerala/" @click="clicked">
                                                    <img src="../../assets/images/services/software.png" alt="#"
                                                         class="mr-4">
                                                    Software Development
                                                </router-link>
                                            </div>
                                            <div @click="clicked">
                                                <router-link to="/web-app-thrissur-kerala/">
                                                    <img src="../../assets/images/services/web-app.png" alt="#"
                                                         class="mr-4">
                                                    Web App Development
                                                </router-link>
                                            </div>
                                            <div @click="clicked">
                                                <router-link to="/web-design-thrissur-kerala/" @click="clicked">
                                                    <img src="../../assets/images/services/website.png" alt="#"
                                                         class="mr-4">
                                                    Website Design
                                                </router-link>
                                            </div>
                                            <div class="d-none" @click="clicked">
                                                <router-link to="/e-commerce-thrissur-kerala/" @click="clicked">
                                                    <img src="../../assets/images/services/ecommerce.png" alt="#"
                                                         class="mr-4">
                                                    Ecommerce Application
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="drop-sub-menu-cont">
                                                <img src="../../assets/images/services/mobile-app.png" alt="#"
                                                     class="mr-4">
                                                <div @click="clicked">
                                                    <router-link class="p-0" to="/mobile-application-development-thrissur-kerala/">
                                                        <div @click="clicked">Mobile App Development</div>
                                                    </router-link>
                                                    <ul class="list-unstyled mt-2 drop-sub-list">
                                                        <router-link to="/android-app-development-thrissur-kerala/">
                                                            <li @click="clicked">Android App Development</li>
                                                        </router-link>
                                                        <router-link to="/ios-app-development-thrissur-kerala/">
                                                            <li @click="clicked">iOS App Development</li>
                                                        </router-link>
                                                        <router-link to="/cross-platform-thrissur-kerala/">
                                                            <li @click="clicked">Cross Platform Application</li>
                                                        </router-link>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
<!--                                        <div class="col-12 col-lg-4">-->
<!--                                            <div class="drop-sub-menu-cont">-->
<!--                                                <img src="../../assets/images/services/digital.png" alt="#"-->
<!--                                                     class="mr-4">-->
<!--                                                <div @click="clicked">-->
<!--                                                    <router-link class="p-0" to="/digital-marketing-thrissur-kerala/">-->
<!--                                                        <div @click="clicked">Digital Marketing</div>-->
<!--                                                    </router-link>-->
<!--                                                    <ul class="list-unstyled mt-2 drop-sub-list">-->
<!--                                                        <router-link to="/seo-services-thrissur-kerala/">-->
<!--                                                            <li @click="clicked">Search Engine Optimization</li>-->
<!--                                                        </router-link>-->
<!--                                                        <router-link to="/social-media-marketing-thrissur-kerala/">-->
<!--                                                            <li @click="clicked">Social Media Marketing</li>-->
<!--                                                        </router-link>-->
<!--                                                        <router-link to="/online-reputation-management-thrissur-kerala/">-->
<!--                                                            <li @click="clicked">Online Reputation Management</li>-->
<!--                                                        </router-link>-->
<!--                                                    </ul>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li @click="clicked">
                        <router-link class="c-menu" to="/products/">Products</router-link>
                    </li>
<!--                    <li class="drop-menu">-->
<!--                        <label class="mega-menu" ref="productLink" for="toggleDrop1">Products</label>-->
<!--                        <input id="toggleDrop1" type="checkbox">-->
<!--                        <div class="drop-menu-list drop-menu-list-2" ref="products">-->
<!--                            <div class="drop-menu-head">-->
<!--                                <img src="../../assets/images/recent-blog/view-more.png" @click="clickedDrop" alt="#">-->
<!--                            </div>-->
<!--                            <div class="drop-menu-head-web">-->
<!--                                <p class="b-gray br-1 font-weight-bold fs-4 mb-0 pr-3">-->
<!--                                    Our Products-->
<!--                                </p>-->
<!--                                <p class="mb-0 ml-3 text-gray poppins-regular">Innovation at its core</p>-->
<!--                                <img src="../../assets/images/close-button.png" class="ml-auto drop-close d-none" alt="#"-->
<!--                                     @click="clicked">-->
<!--                            </div>-->
<!--                            <ul>-->
<!--                                <li class="drop-sub-menu">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-12">-->
<!--                                            <div @click="clicked">-->
<!--                                                <router-link to="/e-commerce-thrissur-kerala/" @click="clicked">-->
<!--                                                    <img src="../../assets/images/services/software.png" alt="#"-->
<!--                                                         class="mr-4">-->
<!--                                                    Ecommerce Application-->
<!--                                                </router-link>-->
<!--                                            </div>-->
<!--                                            <div @click="clicked">-->
<!--                                                <router-link to="/academic-management-thrissur-kerala/">-->
<!--                                                    <img src="../../assets/images/services/web-app.png" alt="#"-->
<!--                                                         class="mr-4">-->
<!--                                                    Academic Management System-->
<!--                                                </router-link>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </li>-->

<!--                            </ul>-->
<!--                        </div>-->
<!--                    </li>-->
                    <li class="d-none" @click="clicked">
                        <router-link class="c-menu" to="/our-works/">Our Works</router-link>
                    </li>
                    <li class="d-none" @click="clicked">
                        <router-link class="c-menu" to="">Career</router-link>
                    </li>
                    <li class="d-none" @click="clicked">
                        <router-link class="c-menu" to="">Blog</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link class="c-menu" to="/contact-us/">Contact Us</router-link>
                    </li>
                </ul>
<!--                <div class="get-quote ml-lg-5" @click="clicked">-->
<!--                    <router-link to="/get-quote/"><img src="@/assets/images/menu-page/quote.png" alt="quote"-->
<!--                                                       class="quote"></router-link>-->
<!--                    &lt;!&ndash;                    <img src="@/assets/images/chat/chat.png" alt="chat" class="chat-icon ml-3">&ndash;&gt;-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainHeader',
    props: {},
    watch: {
        '$route' () {
            // this.checkService();
            this.checkProduct();
        }
    },
    mounted () {
        // this.checkService();
        this.checkProduct();
    },
    methods: {
        clicked () {
            const element = document.getElementById('toggle');
            const element1 = document.getElementById('toggleDrop');
            const element2 = document.getElementById('toggleDrop1');
            element.checked = false;
            if (element1.checked) {
                element1.checked = false;
            }
            if (element2.checked) {
                element2.checked = false;
            }
        },
        close () {
            const element1 = document.getElementById('toggleDrop');
            const element2 = document.getElementById('toggleDrop1');
            element1.checked = false;
            element2.checked = false;
        },
        clickedDrop () {
            const element1 = document.getElementById('toggleDrop');
            const element2 = document.getElementById('toggleDrop1');
            element1.checked = false;
            element2.checked = false;
        },

        // checkService () {
        //     const services = this.$refs.services;
        //     const name = 'router-link-exact-active';
        //     this.$nextTick(function () {
        //         if (services.querySelectorAll('.' + name).length > 0) {
        //             this.$refs.serviceLink.classList.add(name);
        //         } else {
        //             this.$refs.serviceLink.classList.remove(name);
        //         }
        //     });
        // },
        checkProduct () {
            const products = this.$refs.products;
            const services = this.$refs.services;
            const name = 'router-link-exact-active';
            this.$nextTick(function () {
                if (products.querySelectorAll('.' + name).length > 0) {
                    this.$refs.productLink.classList.add(name);
                    this.$refs.serviceLink.classList.remove(name);
                } else if (services.querySelectorAll('.' + name).length > 0) {
                    this.$refs.productLink.classList.remove(name);
                    this.$refs.serviceLink.classList.add(name);
                } else {
                    this.$refs.productLink.classList.remove(name);
                    this.$refs.serviceLink.classList.remove(name);
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
@-webkit-keyframes fadeIn1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.router-link-exact-active {
    color: var(--color-primary);
}

.main-header-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background: white;
}

.main-header {
    flex-direction: row;
    min-height: 60px;
}

.main-header .logo a {
    display: flex;
    align-items: center;
}

.main-header .logo .logo-img {
    width: 150px;
}

.main-header .logo .burger {
    display: none;
}

.nav-list {
    display: flex;
    align-items: center;
}

.nav-list > ul {
    padding: 0;
    list-style-type: none;
}

.nav-list > ul > li {
    padding-left: 1.5em;
    color: #282e2e;
}

.nav-list > ul a {
    text-decoration: none;
}

.nav-list ul a.active {
    color: blue;
}

.get-quote .quote {
    height: 45px;
    width: 170px;
}

.get-quote .chat-icon {
    height: 35px;
    width: 35px;
}

.drop-menu-head {
    display: none;
}

#toggle, #toggleDrop, #toggleDrop1 {
    display: none;
}

.drop-sub-menu img {
    width: 2rem;
}

.drop-sub-menu .drop-sub-menu-cont {
    /*display: block;*/
    width: 100%;
    padding: 8px 0;
    /*border-bottom: 1px solid #bababab5;*/
    transition: 0.3s;
    display: flex;
    align-items: flex-start;
}

.drop-sub-menu a {
    width: 100%;
}

@media (min-width: 1025px) {
    .main-header {
        min-height: 80px;
    }

    .main-header .logo .logo-img {
        width: 180px;
    }

    .drop-close {
        width: 1.8rem;
        filter: brightness(0);
        transition: 0.2s;
    }

    .drop-close:hover {
        filter: brightness(50%);
    }

    .drop-menu {
        position: relative;
    }

    .drop-menu-list {
        display: none;
        background: #fffffff5;
        position: absolute;
        //top: 57px;
        top: 75px;
        /*left: -350px;*/
        /*right: -500px;*/
        left: -480px;
        right: -370px;
        border-radius: 5px;
        padding: 0;
        box-shadow: #3e396b4d 0 6px 16px 0;
        animation: fadeIn1 0.5s ease-in-out;

    }

    .drop-menu-list-2 {
        top: 75px;
        left: -500px;
        right: -170px;
    }

    .drop-menu-list ul {
        list-style: none;
    }

    .drop-menu-list ul li {
        padding: 1rem 2rem 2rem 2rem;
    }

    //#toggleDrop:checked + .drop-menu-list {
    //    /*.drop-menu:hover .drop-menu-list {*/
    //    display: block;
    //}

    //#toggleDrop1:checked + .drop-menu-list {
    .drop-menu:hover .drop-menu-list {
        display: block;
    }

    .drop-sub-menu {
        padding: 0 20px;
    }

    .drop-sub-menu a {
        /*display: block;*/
        width: 100%;
        padding: 8px 0;
        /*border-bottom: 1px solid #bababab5;*/
        transition: 0.3s;
        display: flex;
        align-items: flex-start;
    }

    label {
        transition: 0.3s;
        cursor: pointer;
    }

    label:hover {
        color: var(--color-primary);
    }

    .drop-sub-menu a:last-child {
        border-bottom: none;
    }

    .drop-sub-menu a:hover {
        opacity: 0.75;
    }

    /*.drop-sub-menu a > div {*/
    /*    display: flex;*/
    /*    align-items: flex-start;*/
    /*}*/
    .drop-sub-menu a > div > div {
        width: 100%;
    }

    .drop-sub-menu a > div > div li {
        padding: 0.5rem 0;
    }

    .drop-menu-head-web {
        display: flex;
        align-items: center;
        padding: 2rem 0 1rem 0;
        margin: 0 2rem;
        border-bottom: 1px solid #d2d2d2;
    }

    .drop-sub-list li {
        padding: 0 !important;
    }

    .nav-list > ul .c-menu, .nav-list > ul .mega-menu {
        height: 80px;
        display: flex;
        align-items: center;
    }

}

@media all and (max-width: 1024px) {

    .drop-menu-head-web {
        display: none;
    }

    .drop-menu-head {
        height: 60px;
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
        align-items: center;
        display: flex;
        padding: 0 20px;
        border-bottom: 1px solid #bababab5;
    }

    .drop-menu-head img {
        transform: rotate(180deg);
        width: 1.2rem;
    }

    .drop-menu {
        position: unset;
    }

    .drop-menu-list {
        position: absolute;
        top: -60px;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        padding: 0 !important;
        width: 100%;
        background: white;
        transition: 0.6s;
        border-bottom: 15px solid var(--color-primary);
        transform: translate3d(100%, 0, 0);
        overflow: scroll;
    }

    .drop-sub-menu {
        padding: 0 20px;
    }

    .drop-sub-menu a {
        display: block;
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid #bababab5;
        display: flex;
        align-items: flex-start;
    }

    .drop-sub-list a {
        border-bottom: 0;
    }

    /*.drop-sub-menu a > div {*/
    /*    display: flex;*/
    /*    align-items: flex-start;*/
    /*}*/
    .drop-sub-menu a > div > div {
        width: 100%;
    }

    .drop-sub-menu a > div > div li {
        padding: 0.5rem 0;
    }

    #toggleDrop:checked + .drop-menu-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

    #toggleDrop1:checked + .drop-menu-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

    .nav-list {
        transition: 0.6s;
        transform: translate3d(100%, 0, 0);
        display: unset;
    }

    .nav-list > ul {
        flex-direction: column;
        /*align-items: center;*/
        overflow: scroll;
        height: calc(100vh - 180px);
    }

    .nav-list > ul > li {
        padding: 0;
        margin: 0 1rem;
        border-bottom: 1px solid #c2c2c2ad;
        text-align: left;
        font-size: 1.2rem;
    }

    .nav-list > ul > li > * {
        padding: 1rem 0;
        display: block;
    }

    .get-quote {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0px);
        z-index: -1;
    }

    .get-quote .chat-icon {
        display: none;
    }

    .main-header {
        flex-direction: column;
        justify-content: space-around;
    }

    .main-header .logo {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .main-header .logo .burger {
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    .nav-list {
        position: absolute;
        top: 60px;
        height: calc(100vh - 60px);
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 9999;
        text-align: center;
        margin-top: 0;
        border-bottom: 15px solid var(--color-primary);
    }

    .hamburger {
        z-index: 99999;
    }

    .hamburger div {
        position: relative;
        width: 2em;
        height: 4px;
        border-radius: 3px;
        background-color: var(--color-primary);
        margin-top: 6px;
        transition: all 0.3s ease-in-out;
    }

    .hamburger div:first-child {
        margin-top: 0;
    }

    #toggle:checked + .logo .hamburger .top-bun {
        transform: rotate(-45deg);
        margin-top: 5px;
    }

    #toggle:checked + .logo .hamburger .bottom-bun {
        opacity: 0;
        transform: rotate(45deg);
    }

    #toggle:checked + .logo .hamburger .meat {
        transform: rotate(45deg);
        margin-top: -4px;
    }

    #toggle:checked + .logo + .nav-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

    #toggle:checked + .nav-list {
        transition: 0.6s;
        transform: translate3d(0, 0, 0);
    }

}
</style>
